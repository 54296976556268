export const urls = {
  // User
  signIn: `${process.env.REACT_APP_API_URL}/signin`,
  getUserById: `${process.env.REACT_APP_USER_API_URL}/getUser`,
  updateUser: `${process.env.REACT_APP_USER_API_URL}/updateUser`,
  addUser: `${process.env.REACT_APP_USER_API_URL}/addUser`,

  // Carasouls
  getAllCarousels: `${process.env.REACT_APP_CAROUSEL_API_URL}/allCarasoul`,
  getCarouselById: `${process.env.REACT_APP_CAROUSEL_API_URL}/getCarasoulById/:id`,

  // Products
  allProducts: `${process.env.REACT_APP_PRODUCT_API_URL}/allProducts`,
  getProductbyId: `${process.env.REACT_APP_PRODUCT_API_URL}/getProductById`,
  similarProduct: `${process.env.REACT_APP_PRODUCT_API_URL}/productSimilar`,
  productByCategory: `${process.env.REACT_APP_PRODUCT_API_URL}/getProductByCategory/:id`,

  // Cart
  allItems: `${process.env.REACT_APP_CART_API_URL}/allItems`,
  updateItemDetails: `${process.env.REACT_APP_CART_API_URL}/updateItemDetails/:id`,
  deleteItemFromCart: `${process.env.REACT_APP_CART_API_URL}/deleteItemFromCart/:id`,
  addItem: `${process.env.REACT_APP_CART_API_URL}/addItem`,
  clearCart: `${process.env.REACT_APP_CART_API_URL}/clearCart`,

  // FAQ
  getAllFAQ: `${process.env.REACT_APP_FAQ_API_URL}/getAllFAQ`,

  // Address
  createAddress: `${process.env.REACT_APP_ADDRESS_API_URL}/createAddress`,
  getAddressById: `${process.env.REACT_APP_ADDRESS_API_URL}/getAddressById/:id`,
  getAddressesByUser: `${process.env.REACT_APP_ADDRESS_API_URL}/getAddressByUser`,
  updateAddress: `${process.env.REACT_APP_ADDRESS_API_URL}/updateAddress/:id`,
  deleteAddress: `${process.env.REACT_APP_ADDRESS_API_URL}/deleteAddress/:id`,

  // Sizes
  sizes: `${process.env.REACT_APP_SIZE_API_URL}`,

  // Discount
  applyDiscount: `${process.env.REACT_APP_DISCOUNT_API_URL}/applyDiscount`,

  // Categories
  getAllCategories: `${process.env.REACT_APP_CATEGORIE_API_URL}/allCategories`,

  // payment
  payment: `${process.env.REACT_APP_PAYMENT_API_URL}/payment`,

  // Order
  getAllOrder: `${process.env.REACT_APP_ORDER_API_URL}/getAllOrderByUserId`,
};
