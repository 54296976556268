import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  CircularProgress,
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { Flag } from "@mui/icons-material";
import Swal from "sweetalert2"; // Make sure to import SweetAlert
import { useNavigate, useLocation } from "react-router-dom"; // Import useLocation

import { apiConfig } from "../../utils/apiConfig";

export const AddAddress = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Use useLocation to access the state
  const [formData, setFormData] = useState({
    country: "India",
    firstName: "",
    lastName: "",
    phone: "",
    pinCode: "",
    city: "",
    state: "",
    street: "",
    area: "",
    landmark: "",
    addressType: "Other",
  });
  const [loading, setLoading] = useState(false);

  // Extract the id from location state
  const { id } = location.state || {}; // `id` will be in location.state if passed via state

  // Check if all required fields are filled
  const isFormValid = () => {
    const requiredFields = [
      "firstName",
      "lastName",
      "phone",
      "postalCode",
      "city",
      "state",
      "street",
      "area",
      "addressType",
    ];
    return requiredFields.every((field) => formData[field]);
  };

  const handleAddressTypeChange = (event, newType) => {
    if (newType) {
      setFormData((prevData) => ({
        ...prevData,
        addressType: newType,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("saving address");

    // Show loading spinner or indication that the request is in progress
    setLoading(true);

    try {
      if (id) {
        // If `id` is present, update the address
        const response = await apiConfig.updateAddress(id, formData); // Use update API
        Swal.fire({
          title: "Success",
          text: "Address updated successfully!",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          navigate("/my-account/addresses"); // Redirect to address list after success
        });
      } else {
        // Add new address if `id` is not present
        const response = await apiConfig.createAddress(formData);
        Swal.fire({
          title: "Success",
          text: "Address added successfully!",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          navigate("/my-account/addresses"); // Redirect to address list after success
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Error",
        text: err.response?.data?.message || "Failed to save address",
        icon: "error",
        confirmButtonText: "Try Again",
      });
    } finally {
      setLoading(false);
    }
  };

  // Fetch address details when the `id` is present (for editing)
  useEffect(() => {
    if (id) {
      const fetchAddressData = async () => {
        try {
          setLoading(true);
          const response = await apiConfig.getAddressById(id); // Replace with actual API call
          setFormData(response?.data?.data); // Set the form data with the fetched address details
        } catch (err) {
          Swal.fire({
            title: "Error",
            text: "Failed to load address data",
            icon: "error",
            confirmButtonText: "OK",
          });
        } finally {
          setLoading(false);
        }
      };

      fetchAddressData();
    }
  }, [id]);

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "60rem",
        margin: "0 auto",
        padding: 2,
      }}
    >
      <Grid container spacing={2}>
        {/* Country Field */}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Country*</InputLabel>
            <Select
              label="Country"
              name="country"
              value={formData.country}
              onChange={handleChange}
            >
              <MenuItem value="India">
                <Flag sx={{ marginRight: 1 }} />
                India
              </MenuItem>
              {/* Add more countries if needed */}
            </Select>
          </FormControl>
        </Grid>

        {/* First Name */}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="First Name"
            variant="outlined"
            required
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
          />
        </Grid>

        {/* Last Name */}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Last Name"
            variant="outlined"
            required
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
          />
        </Grid>

        {/* Mobile Number */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Mobile Number"
            variant="outlined"
            required
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <Typography sx={{ paddingRight: 1 }}>+91 </Typography>
              ),
            }}
          />
        </Grid>

        {/* PIN Code */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="PIN Code/Postal Code/ZIP Code"
            variant="outlined"
            required
            name="postalCode"
            value={formData.postalCode}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        {/* City and State */}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="City"
            variant="outlined"
            required
            name="city"
            value={formData.city}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="State"
            variant="outlined"
            required
            name="state"
            value={formData.state}
            onChange={handleChange}
          />
        </Grid>

        {/* Address Details */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Flat no/Building, Street name"
            variant="outlined"
            required
            name="street"
            value={formData.street}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Area/Locality"
            variant="outlined"
            required
            name="area"
            value={formData.area}
            onChange={handleChange}
          />
        </Grid>

        {/* Landmark */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Landmark"
            variant="outlined"
            name="landmark"
            value={formData.landmark}
            onChange={handleChange}
          />
        </Grid>

        {/* Address Type */}
        <Grid item xs={4}>
          <ToggleButtonGroup
            value={formData.addressType}
            exclusive
            onChange={handleAddressTypeChange}
            aria-label="address type"
            fullWidth
          >
            <ToggleButton value="Home" aria-label="Home">
              Home
            </ToggleButton>
            <ToggleButton value="Office" aria-label="Office">
              Office
            </ToggleButton>
            <ToggleButton value="Other" aria-label="Other">
              Other
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>

        {/* Save Button */}
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            sx={{
              backgroundColor: "black",
              color: "white",
              textTransform: "none",
              height: "40px",
              display: "flex", // To align the CircularProgress and text properly
              justifyContent: "center", // Center content horizontally
              alignItems: "center", // Center content vertically
              "&:hover": {
                backgroundColor: "#333",
              },
            }}
            onClick={handleSubmit}
            disabled={loading || !isFormValid()} // Disable the button when loading or if form is incomplete
          >
            {loading ? (
              <CircularProgress
                size={24}
                sx={{ color: "white" }} // White color for the progress spinner
              />
            ) : (
              "Save Address"
            )}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
