import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  Divider,
  List,
  ListItem,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";

// Custom Connector for the Stepper
const CustomConnector = styled(StepConnector)(({ theme }) => ({
  "& .MuiStepConnector-line": {
    borderColor: theme.palette.success.main, // Green for completed steps
    borderTopWidth: 3,
  },
}));

// Styled Step Label
const CustomStepLabel = styled(StepLabel)(({ theme }) => ({
  "& .MuiStepLabel-label": {
    color: theme.palette.success.main, // Green text for completed steps
    fontWeight: "bold",
  },
}));

const MoreDetails = () => {
  const [activeStep, setActiveStep] = useState(0); // Default to step 0 (Order Placed)
  const [transition, setTransition] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);

  const location = useLocation(); // Access the location object
  const { orderDetail } = location.state || {}; // Retrieve the product from state

  const orderData = {
    orderId: "6738dbd425b8f2852c24cad0",
    orderDate: "16/11/2024",
    total: "₹1490",
    transactionId: "19qxsz26m3kgt1yb",
    paymentStatus: "Completed",
    orderStatus: "pending", // Example order status
    userDetails: {
      name: "Jatin Verma",
      email: "jatinv85276@gmail.com",
      phone: "8882081629",
    },
    deliveryAddress:
      "Sarwati hospital, main, Taj Hwy, near Shiv mandir,, Greater noida, Uttar Pradesh, 201009, India",
    products: [
      {
        name: "Men's Black Across The Spiderverse Graphic Printed Oversized T-shirt",
        description: "Just Checking",
        price: "₹690",
        quantity: 1,
        size: "L",
      },
      {
        name: "Men's Black Crest Mark Graphic Printed Oversized Acid Wash T-shirt",
        description:
          "Men's Black Crest Mark Graphic Printed Oversized Acid Wash T-shirt",
        price: "₹800",
        quantity: 1,
        size: "XL",
      },
    ],
  };

  const steps = ["Order Placed", "Shipped", "Out for Delivery", "Delivered"];

  // Map orderStatus to activeStep index
  const getStepIndexFromStatus = (status) => {
    switch (status) {
      case "shipped":
        return 1;
      case "out for delivery":
        return 2;
      case "delivered":
        return 3;
      case "pending":
      default:
        return 0;
    }
  };

  // Transition Effect Trigger
  useEffect(() => {
    setOrderDetails(orderDetail);
    setTransition(true);
    const timer = setTimeout(() => {
      setTransition(false);
    }, 300); // Duration of the fade transition

    // Map order status to active step
    if (orderDetail?.orderStatus) {
      const stepIndex = getStepIndexFromStatus(
        orderDetail.orderStatus.toLowerCase()
      );
      setActiveStep(stepIndex);
    }

    return () => clearTimeout(timer); // Cleanup on component unmount
  }, [orderDetail]);

  return (
    <Box sx={{ padding: 3, borderRadius: 2, border: "1px solid #ddd" }}>
      {/* Title */}
      <Typography variant="h5" fontWeight="bold" gutterBottom>
        Order Details
      </Typography>
      <Divider sx={{ marginBottom: 2 }} />

      {/* Progress Bar */}
      <Box mb={4}>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          Order Status
        </Typography>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<CustomConnector />}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <CustomStepLabel>
                <Typography
                  variant="body2"
                  sx={{
                    color: index <= activeStep ? "green" : "gray",
                    fontWeight: index <= activeStep ? "bold" : "normal",
                    opacity: transition && index === activeStep ? 0.5 : 1, // Apply fade effect
                    transition: "opacity 0.3s ease-in-out", // Transition effect for opacity
                  }}
                >
                  {label}
                </Typography>
              </CustomStepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      {/* Order Info */}
      <Box mb={2}>
        <Typography variant="body1">
          <strong>Order ID:</strong> {orderDetails?._id}
        </Typography>
        <Typography variant="body1">
          <strong>Order Date:</strong> {orderDetails?.createdAt}
        </Typography>
        <Typography variant="body1">
          <strong>Total:</strong> ₹ {orderDetails?.totalAmount}
        </Typography>
        <Typography variant="body1">
          <strong>Transaction ID:</strong> {orderDetails?.transactionId}
        </Typography>
        <Typography variant="body1">
          <strong>Payment Status:</strong>{" "}
          <span style={{ color: "green" }}>{orderDetails?.paymentStatus}</span>
        </Typography>
        <Typography variant="body1">
          <strong>Order Status:</strong>{" "}
          <span style={{ color: "orange" }}>{orderDetails?.orderStatus}</span>
        </Typography>
      </Box>

      {/* User Details */}
      {/* Delivery Address */}
      <Box mb={2}>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          Delivery Address
        </Typography>
        {orderDetails?.address?.firstName} {orderDetails?.address?.lastName}
        <Typography variant="body1">
          {`${orderDetails?.address?.street}, ${orderDetails?.address?.city}, ${orderDetails?.address?.state}, ${orderDetails?.address?.postalCode}`}
        </Typography>
        {orderDetails?.address?.phone}
      </Box>

      {/* Products List */}
      <Box>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          Products
        </Typography>
        <List>
          {orderDetails?.products?.map((item, index) => (
            <ListItem
              key={index}
              disableGutters // Trigger navigation on click
              sx={{ cursor: "pointer" }}
            >
              <Box
                sx={{
                  width: "100%",
                  border: "1px solid #ddd",
                  borderRadius: 2,
                  padding: 1.3,
                  marginBottom: 2,
                }}
              >
                <Grid container spacing={5} alignItems="center">
                  {/* Product Image */}
                  <Grid item xs={3}>
                    <div>
                      <figure>
                        <img
                          src={item?.product?.primaryImageUrl}
                          alt="Product"
                          style={{ width: "100%", height: "auto" }}
                        />
                      </figure>
                    </div>
                  </Grid>

                  {/* Product Details */}
                  <Grid item xs={8}>
                    <p>{item?.product?.productName}</p>
                    <Grid container spacing={2} sx={{ marginTop: 1 }}>
                      <Grid item xs={4}>
                        <Typography variant="body2">
                          <strong>Price:</strong> {item?.product?.sellingPrice}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body2">
                          <strong>Quantity:</strong> {item?.quantity}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body2">
                          <strong>Size:</strong> {item?.size}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default MoreDetails;
