import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "axios";
import { apiConfig } from "../../utils/apiConfig";

const Profile = () => {
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  // Fetch user data from API when component mounts
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await apiConfig.getUserById(); // Adjust the endpoint as needed
        setUserData(response?.data?.data);
      } catch (err) {
        setError("Failed to fetch user data");
      }
    };

    fetchUserData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const response = await apiConfig.updateUser(userData); // Adjust the endpoint as needed
      setSuccess("Profile updated successfully");
      setUserData(response.data); // Update the local state with the new data
    } catch (err) {
      setError(err.response?.data?.message || "Failed to update profile");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        marginBottom: 2,
        border: "1px solid #ddd", // Border styling
        borderRadius: "8px", // Rounded corners
        padding: 3, // Add padding inside the border
      }}
    >
      <form onSubmit={handleSubmit}>
        {/* First Name and Last Name in a single row */}
        <Box display="flex" justifyContent="space-between">
          <TextField
            fullWidth
            label="First Name"
            name="firstName"
            value={userData.firstName}
            onChange={handleChange}
            required
            margin="normal"
            sx={{ marginRight: 1 }} // Add spacing between fields
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            fullWidth
            label="Last Name"
            name="lastName"
            value={userData.lastName}
            onChange={handleChange}
            required
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        <TextField
          fullWidth
          label="Email"
          name="email"
          type="email"
          value={userData.email}
          onChange={handleChange}
          required
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          fullWidth
          label="Phone Number"
          name="phone"
          type="number"
          value={userData.phone}
          onChange={handleChange}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
          sx={{
            marginTop: 2,
            backgroundColor: "black",
            color: "white",
            "&:hover": {
              backgroundColor: "#333",
            },
          }}
        >
          {loading ? "Updating..." : "Update Profile"}
        </Button>
      </form>

      <Snackbar
        open={!!error}
        autoHideDuration={4000}
        onClose={() => setError("")}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={() => setError("")} severity="error">
          {error}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!success}
        autoHideDuration={4000}
        onClose={() => setSuccess("")}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={() => setSuccess("")} severity="success">
          {success}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Profile;
