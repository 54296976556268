import React from "react";
import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import "./css/policy.css";
import CustomBreadcrumbs from "../components/CustomBreadcrumbs";

const RefundAndCancellationPolicy = () => {
  return (
    <>
      <Navbar />
      <div
        className="container"
        style={{ marginTop: "110px", marginBottom: "50px" }}
      >
        <h5>Refund and Cancellation Policy</h5>
        <p>
          This cancellation policy outlines how you can cancel or seek a refund
          for a product or service that you have purchased through the Platform.
        </p>

        <h6>Cancellation Requests</h6>
        <p>
          Cancellations will only be considered if the request is made within{" "}
          <strong>7 days</strong> of placing the order. However, cancellation
          requests may not be entertained if the orders have been communicated
          to sellers/merchants listed on the Platform and they have initiated
          the process of shipping the product, or if the product is out for
          delivery. In such an event, you may choose to reject the product at
          the doorstep.
        </p>

        <h6>Return and Exchange Policy</h6>
        <p>
          We offer a refund or exchange within the first <strong>7 days</strong>{" "}
          from the date of your purchase. If 7 days have passed since your
          purchase, you will not be offered a return, exchange, or refund of any
          kind. To be eligible for a return or an exchange, the purchased item
          should be unused, in the same condition as received, and must have its
          original packaging.
        </p>
        <p>
          Items purchased on sale may not be eligible for return or exchange.
          Only items found to be defective or damaged may be replaced, based on
          an exchange request.
        </p>

        <h6>Product Not as Expected</h6>
        <p>
          If you feel that the product received is not as shown on the site or
          does not meet your expectations, you must notify our customer service
          within <strong>7 days</strong> of receiving the product. The customer
          service team will review your complaint and make an appropriate
          decision.
        </p>

        <h6>Refund Processing</h6>
        <p>
          In case of refunds approved by <strong>TheMonkeySoul</strong>, it will
          take <strong>7 days</strong> for the refund to be processed and
          credited to your original payment method.
        </p>

        <p>
          For return or exchange requests, send an email to{" "}
          <a href="mailto:support@themonkeysoul.com">
            support@themonkeysoul.com
          </a>
          . We will notify you via email once we receive and inspect the
          returned/exchanged product. If approved after our quality check, your
          request will be processed in accordance with our policies.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default RefundAndCancellationPolicy;
