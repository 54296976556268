import React, { useEffect } from "react";
import "./css/confirmation.css"; // Import custom CSS for styling
import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { apiConfig } from "../utils/apiConfig";

const OrderConfirmation = () => {
  useEffect(() => {
    const clearCart = async () => {
      await apiConfig.clearCart();
    };
    clearCart();
  }, []);
  return (
    <>
      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <Navbar />
        <div
          className="custom-order-container"
          style={{ flex: 1, marginTop: "110px" }}
        >
          <div className="confirmation-card">
            <h2>🎉 Congratulations!</h2>
            <h4>Your order has been placed successfully.</h4>
            <p>
              We are processing your order and will notify you when it’s ready
              for shipping.
            </p>
            <Link to={"/"} className="btn-order">
              Go to Home
            </Link>
          </div>
        </div>
        <Footer />
      </Box>
    </>
  );
};

export default OrderConfirmation;
