import { Box, Typography, Avatar, Button, Grid, Card } from "@mui/material";
import {
  LocationOn,
  AccountCircle,
  ShoppingCart,
  Payment,
  HelpOutline,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
export const MyAccountOverview = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        flexGrow: 1,
        paddingLeft: { xs: 0 }, // No padding on mobile
      }}
    >
      {/* Profile Section */}
      {/* <Grid container spacing={3} marginBottom={2}>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "20px",
              flexDirection: { xs: "column", sm: "row" }, // Stacks vertically on small screens
              textAlign: { xs: "center", sm: "left" }, // Center-aligns text on mobile
              backgroundColor: "#fffae6",
              border: "1px solid #ddd",
              borderRadius: "8px",
              boxShadow: "none",
            }}
          >
            <Avatar
              sx={{
                bgcolor: "#ffd700",
                width: 50,
                height: 50,
                mb: { xs: 2, sm: 0 }, // Adds spacing below Avatar on mobile
                mr: { xs: 0, sm: 2 }, // Removes margin on mobile
              }}
            >
              J
            </Avatar>
            <Box>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Jatin
              </Typography>
              <Typography variant="body2" sx={{ color: "gray" }}>
                shoatfreelance226@gmail.com
              </Typography>
              <Typography variant="body2" sx={{ color: "gray" }}>
                8587813603
              </Typography>
            </Box>
            <Button
              variant="contained"
              sx={{
                marginLeft: { xs: 0, sm: "auto" }, // Centers button on mobile
                marginTop: { xs: 2, sm: 0 }, // Adds spacing on mobile
                textTransform: "none",
                backgroundColor: "#ffd700",
                color: "black",
                "&:hover": {
                  backgroundColor: "#f5c400",
                },
              }}
            >
              Edit Profile
            </Button>
          </Card>
        </Grid>
      </Grid> */}

      {/* Dashboard Cards */}
      <Grid container spacing={2}>
        {[
          {
            icon: <ShoppingCart />,
            title: "My Orders",
            route: "/my-account/orders",
          },
          {
            icon: <Payment />,
            title: "My Payments",
            route: "payments",
          },
          {
            icon: <LocationOn />,
            title: "My Addresses",
            route: "/my-account/addresses",
          },
          {
            icon: <AccountCircle />,
            title: "My Profile",
            route: "profile",
          },
          {
            icon: <HelpOutline />,
            title: "Help & Support",
          },
        ].map((card, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card
              onClick={() => navigate(card.route)}
              sx={{
                padding: "20px",
                textAlign: "center",
                border: "1px solid #ddd",
                borderRadius: "8px",
                cursor: "pointer",
                "&:hover": {
                  borderColor: "#888",
                  backgroundColor: "#f9f9f9",
                },
                boxShadow: "none",
              }}
            >
              <Box sx={{ fontSize: "2rem", color: "#666", mb: 1 }}>
                {card.icon}
              </Box>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                {card.title}
              </Typography>
              <Typography variant="body2" sx={{ color: "gray", marginTop: 1 }}>
                {card.desc}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
