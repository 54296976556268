// src/components/Footer.js
import React from "react";
import "./css/footer.css"; // Ensure to link your CSS for styling
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-light text-dark">
      <div className="footer-check">
        <div className="row">
          <div className="col-md-3 col-sm-6 mb-4">
            <h6>About Us</h6>
            <p>
              Bold, authentic, and crafted for self-expression. TheMonkeySoul
              brings you fashion with soul—because style should tell your story.
            </p>
          </div>
          <div className="col-md-3 col-sm-6 mb-4">
            <h6>Customer Service</h6>
            <p>
              <Link to={"/contact-us/faq"}>FAQ</Link>
            </p>
            {/* <p><a href="#returns">Returns</a></p> */}
            {/* <p><a href="#shipping">Shipping Info</a></p> */}
            <p>
              <a href="#contact">Contact Us</a>
            </p>
          </div>
          <div className="col-md-3 col-sm-6 mb-4">
            <h6>Quick Links</h6>
            <p>
              <Link to={"/terms-and-condition"}>Terms & Conditions</Link>
            </p>
            <p>
              <Link to={"/privacy-policy"}>Privacy Policy</Link>
            </p>
            <p>
              <Link to={"/refund-and-cancellation-policy"}>
                Refund & Cancellation Policy
              </Link>
            </p>
            <p>
              <Link to={"/shipping-policy"}>Shipping Policy</Link>
            </p>
          </div>
          <div className="col-md-3 col-sm-6 mb-4">
            <h6>Follow Us</h6>
            <p>
              <a href="#facebook">
                <i className="fab fa-facebook-f"></i> Facebook
              </a>
            </p>
            <p>
              <a href="#twitter">
                <i className="fab fa-twitter"></i> Twitter
              </a>
            </p>
            <p>
              <a href="#instagram">
                <i className="fab fa-instagram"></i> Instagram
              </a>
            </p>
            <p>
              <a href="#linkedin">
                <i className="fab fa-linkedin"></i> LinkedIn
              </a>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col text-center">
            <p>
              &copy; {new Date().getFullYear()} JH Fashion. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
