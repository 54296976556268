import React, { useState, useEffect } from 'react';
import {
    Container,
    Typography,
    TextField,
    Button,
    Box,
    Paper,
    Tabs,
    Tab,
    Avatar,
    Grid,
    Snackbar,
    Alert
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiConfig } from '../utils/apiConfig';

export const SignIn = () => {
    const [activeTab, setActiveTab] = useState(0);
    const location = useLocation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [signUpData, setSignUpData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
    });

    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token'); // Replace 'token' with your actual token key
        const role = localStorage.getItem('role');

        // If token is found and role is admin, redirect to dashboard
        if (token && role === "admin") {
            navigate("/");
        }
    }, [navigate]);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleSignIn = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const response = await apiConfig.signIn({
                email,
                password,
            });

            console.log(response, "Response");

            const { token, user } = response.data; // Assuming the API returns a token and user info

            // Save the token and user details to localStorage
            localStorage.setItem('token', token);
            localStorage.setItem('name', user.name);
            localStorage.setItem('isLoggedIn', true)

            const from = location.state?.from || '/'; // Default to home if no previous location
            navigate(from);
        } catch (err) {
            // Set the error message
            if (err.response && err.response.data) {
                setError(err.response.data.message || 'Sign-in failed');
            } else {
                setError('Sign-in failed');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSignUpData({ ...signUpData, [name]: value });
    };

    const handleSignUp = async (e) => {
        e.preventDefault()
        setLoading(true);
        setError('');

        try {
            const response = await apiConfig.addUser(signUpData); // Adjust the endpoint as needed
            if(response.status === 201){
                const { token, data } = response?.data; // Assuming the API returns a token and user info
                console.log(response.data.data)
                // Save the token and user details to localStorage
                localStorage.setItem('token', token);
                localStorage.setItem('name', data.name);
                localStorage.setItem('isLoggedIn', true)
                navigate('/')
            }
        } catch (err) {
            console.log(err.response,"Chekingingn")
            setError(err.response?.data?.message || 'Failed to SignUp profile');
        } finally {
            setLoading(false);
        }

    }

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh', // Full viewport height
                backgroundColor: '#f0f0f0' // Optional background color
            }}
        >
            <Container component="main" maxWidth="xs">
                <Paper elevation={3} sx={{ padding: 3 }}>
                    {/* Logo Placeholder */}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: 2 // Space below the logo
                        }}
                    >
                        <Avatar
                            alt="Company Logo"
                            src="/path/to/logo.png" // Replace with your logo path
                            sx={{ width: 100, height: 100 }} // Adjust the size as needed
                        />
                    </Box>

                    <Tabs value={activeTab} onChange={handleTabChange} centered>
                        <Tab label="Sign In" />
                        <Tab label="Sign Up" />
                    </Tabs>
                    {activeTab === 0 && (
                        <Box component="form" noValidate onSubmit={handleSignIn}>
                            <Typography variant="h5" component="h1" gutterBottom>
                                Sign In
                            </Typography>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            {error && (
                                <Alert severity="error" sx={{ marginBottom: 2 }}>
                                    {error}
                                </Alert>
                            )}
                            <Button type="submit" fullWidth variant="contained" color="primary" disabled={loading}>
                                {loading ? 'Signing In...' : 'Sign In'}
                            </Button>
                        </Box>
                    )}
                    {activeTab === 1 && (
                        <Box component="form" noValidate onSubmit={handleSignUp}>
                            <Typography variant="h5" component="h1" gutterBottom>
                                Sign Up
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="firstName"
                                        label="First Name"
                                        name="firstName"
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="lastName"
                                        label="Last Name"
                                        name="lastName"
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="phone"
                                label="Phone"
                                name="phone"
                                autoComplete="phone"
                                onChange={handleChange}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                onChange={handleChange}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                onChange={handleChange}
                            />
                            {error && (
                                <Alert severity="error" sx={{ marginBottom: 2 }}>
                                    {error}
                                </Alert>
                            )}
                            <Button type="submit" fullWidth variant="contained" color="primary">
                                Sign Up
                            </Button>
                        </Box>
                    )}
                </Paper>
            </Container>
        </Box>
    );
};

