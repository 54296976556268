import "./css/product.css";

import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { apiConfig } from "../utils/apiConfig";
import ProductSkeleton from "./skeleton/ProductSkeleton";
import CustomBreadcrumbs from "./CustomBreadcrumbs";

export const Products = ({ categoryId, catergoryName }) => {
  const [allProducts, setAllProducts] = useState([]);
  const [loading, setLoading] = useState(true); // State to manage loading status
  const location = useLocation();

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true); // Set loading to true before fetching
      try {
        let response;
        if (categoryId) {
          // If categoryId is present, fetch products by category
          response = await apiConfig.getProductsByCategory(categoryId);
        } else {
          // Otherwise, fetch all products
          response = await apiConfig.allProducts();
        }
        setAllProducts(response.data.data); // Assuming response.data contains the array of products
      } catch (err) {
        console.error("Error fetching products:", err);
      } finally {
        setLoading(false); // Set loading to false after fetching or on error
      }
    };

    fetchProducts();
  }, [categoryId, catergoryName]); // Re-run the effect if categoryId changes

  const marginTop = location.pathname === "/" ? "50px" : "110px";
  return (
    <>
      {loading ? (
        <ProductSkeleton />
      ) : (
        <>
          <section style={{ marginTop }}>
            {/* <CustomBreadcrumbs /> */}
            <div className="my-5">
              <h4 className="ms-1">
                {catergoryName ? catergoryName : `Products Overview`}
              </h4>
              <div className="row mt-5 mx-0">
                {allProducts?.map((product) => (
                  <div
                    className="col-6 col-md-4 col-lg-2-4 mb-4 col-custom"
                    key={product._id}
                    style={{ paddingLeft: "0", paddingRight: "0" }}
                  >
                    <Link
                      to={`/product/${product._id}`}
                      className="no-underline"
                    >
                      <div className="product">
                        <div className="product-card">
                          <img
                            src={product.primaryImageUrl}
                            className="card-img-top"
                            alt={product.productName}
                          />
                        </div>
                        <div className="mt-3 prod-desc">
                          <p>{product.productName}</p>
                          <span>
                            <span
                              style={{ fontSize: "14px", fontWeight: "600" }}
                            >{`₹${product.sellingPrice}`}</span>
                            &nbsp;&nbsp;
                            <span
                              style={{
                                textDecoration: "line-through",
                                marginRight: 5,
                                fontSize: "14px",
                                color: "grey",
                              }}
                            >{`₹${product.price}`}</span>
                          </span>
                          <span style={{ color: "green", marginLeft: 10 }}>
                            {`${Math.round(
                              ((product.price - product.sellingPrice) /
                                product.price) *
                                100
                            )}% off`}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};
