const initialState = {
  items: [],
  totalQuantity: 0,
  totalAmount: 0,
};

const cartReducer = (state = initialState, action) => {
  console.log(action.payload, "checking");
  switch (action.type) {
    case "ADD_TO_CART":
      const updatedItems = [
        ...state.items.filter(
          (item) => item.productId._id !== action.payload.productId._id
        ),
        action.payload, // Add the latest item
      ];

      // Calculate the total quantity of items in the cart
      const updatedQuantity = updatedItems.reduce(
        (acc, item) => acc + item.quantity,
        0
      );

      // Calculate the total amount of the cart
      const updatedAmount = updatedItems.reduce(
        (acc, item) => acc + item.quantity * item.productId.sellingPrice,
        0
      );

      return {
        ...state,
        items: updatedItems,
        totalQuantity: updatedQuantity,
        totalAmount: updatedAmount,
      };
    case "REMOVE_FROM_CART":
      // Filter out the item to remove from the cart
      const filteredItems = state.items.filter(
        (item) => item._id !== action.payload
      );

      // Calculate the updated total quantity and amount after removal
      const newTotalQuantity = filteredItems.reduce(
        (acc, item) => acc + item.quantity,
        0
      );
      const newTotalAmount = filteredItems.reduce(
        (acc, item) => acc + item.quantity * item.productId.sellingPrice,
        0
      );

      return {
        ...state,
        items: filteredItems,
        totalQuantity: newTotalQuantity,
        totalAmount: newTotalAmount,
      };
    case "SET_CART_ITEMS":
      return {
        ...state,
        items: action.payload.items,
        totalQuantity: action.payload.items.reduce(
          (acc, item) => acc + item.quantity,
          0
        ),
        totalAmount: action.payload?.productId?.sellingPrice,
      };
    default:
      return state;
  }
};

export default cartReducer;
