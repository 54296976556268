import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min"; // For Bootstrap JS functionality

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./routes/ProtectedRoute";
import { SignIn } from "./pages/SignIn";
import { MainPage } from "./pages/MainPage";
import { Shop } from "./pages/Shop";
import ProductOverview from "./pages/ProductOverview";
import FAQ from "./pages/FAQ";
import MyAccount from "./pages/MyAccount";
import { Cart } from "./pages/Cart";
import { Blogs } from "./pages/Blogs";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ShippingPolicy from "./pages/ShippingPolicy";
import RefundAndCancellationPolicy from "./pages/RefundAndCancellationPolicy";
import ScrollToTop from "./components/utils/ScrollToTop";
import OrderConfirmation from "./pages/OrderConfirmation";
import OrderHistory from "./components/MyAccount/OrderHistory";
import AddressManager from "./components/MyAccount/AddressManager";
import { MyAccountOverview } from "./components/MyAccount/MyAccountOverview";
import Profile from "./components/MyAccount/Profile";
import MoreDetails from "./components/MyAccount/MoreDetails";

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/" element={<MainPage />} />
          <Route path="/product" element={<Shop />} />
          <Route path="/product/:productId" element={<ProductOverview />} />
          <Route
            path="/category/:catergoryName/:categoryId"
            element={<Shop />}
          />
          <Route path="/contact-us/faq" element={<FAQ />} />
          <Route path="/terms-and-condition" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/refund-and-cancellation-policy"
            element={<RefundAndCancellationPolicy />}
          />
          <Route path="/shipping-policy" element={<ShippingPolicy />} />
          <Route
            path="/cart"
            element={
              <ProtectedRoute>
                <Cart />
              </ProtectedRoute>
            }
          />
          <Route path="/blog" element={<Blogs />} />
          <Route path="/order-confirmation" element={<OrderConfirmation />} />

          <Route
            path="/my-account"
            element={
              <ProtectedRoute>
                <MyAccount />
              </ProtectedRoute>
            }
          />
          <Route
            path="/my-account/orders"
            element={
              <ProtectedRoute>
                <MyAccount />
              </ProtectedRoute>
            }
          />
          {/* <Route path="/my-account/payments" element={<MyPayments />} /> */}
          <Route
            path="/my-account/addresses"
            element={
              <ProtectedRoute>
                <MyAccount />
              </ProtectedRoute>
            }
          />
          <Route
            path="/my-account/addresses/add"
            element={
              <ProtectedRoute>
                <MyAccount />
              </ProtectedRoute>
            }
          />
          <Route
            path="/my-account/profile"
            element={
              <ProtectedRoute>
                <MyAccount />
              </ProtectedRoute>
            }
          />
          <Route
            path="/my-account/order/moreInfo"
            element={
              <ProtectedRoute>
                <MyAccount />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
