import { urls } from "./urls";
import axios from "axios";

const getToken = () => localStorage.getItem("token");

export const apiConfig = {
  // api base url
  // Get all carousel items
  signIn: async function ({ email, password }) {
    try {
      const response = await axios.post(urls.signIn, {
        email,
        password,
      });
      return response;
    } catch (error) {
      console.error("Failed to Sign In:", error);
      throw error;
    }
  },

  getUserById: async function () {
    try {
      const response = await axios.get(urls.getUserById, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error fetching user by ID:", error);
      throw error;
    }
  },

  updateUser: async function (updatedData) {
    try {
      const response = await axios.patch(urls.updateUser, updatedData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error updating user:", error);
      throw error;
    }
  },

  addUser: async function (userData) {
    try {
      const response = await axios.post(urls.addUser, userData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error adding user:", error);
      throw error;
    }
  },

  getAllCarousels: async function () {
    try {
      const response = await axios.get(urls.getAllCarousels, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error fetching all carousel items:", error);
      throw error;
    }
  },

  // Get carousel item by ID
  getCarouselById: async function (carouselId) {
    try {
      const response = await axios.get(
        urls.getCarouselById.replace(":id", carouselId),
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error(
        `Error fetching carousel item with ID ${carouselId}:`,
        error
      );
      throw error;
    }
  },

  allProducts: async function () {
    try {
      const response = await axios.get(`${urls.allProducts}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`, // Pass the token in the headers
        },
      });
      return response;
    } catch (error) {
      console.error("Error adding product:", error);
      throw error; // Throw error to be handled by the calling function
    }
  },

  getProductById: async function (productId) {
    try {
      const response = await axios.get(`${urls.getProductbyId}/${productId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`, // Pass the token in the headers
        },
      });
      return response;
    } catch (error) {
      console.error("Error adding product:", error);
      throw error; // Throw error to be handled by the calling function
    }
  },

  getSimilarProduct: async function (data) {
    try {
      const response = await axios.post(`${urls.similarProduct}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`, // Pass the token in the headers
        },
      });
      return response;
    } catch (error) {
      console.error("Error adding product:", error);
      throw error; // Throw error to be handled by the calling function
    }
  },

  getProductsByCategory: async function (id) {
    try {
      const response = await axios.get(
        `${urls.productByCategory.replace(":id", id)}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`, // Pass the token in the headers
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Error adding product:", error);
      throw error; // Throw error to be handled by the calling function
    }
  },

  getAllFAQ: async function () {
    try {
      const response = await axios.get(`${urls.getAllFAQ}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`, // Pass the token in the headers
        },
      });
      return response;
    } catch (error) {
      console.error("Error adding product:", error);
      throw error; // Throw error to be handled by the calling function
    }
  },

  // All Cart Function
  allItems: async function () {
    try {
      const response = await axios.get(urls.allItems, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error fetching all items:", error);
      throw error;
    }
  },

  updateItemDetails: async function (itemId, quantityData) {
    try {
      const response = await axios.patch(
        `${urls.updateItemDetails.replace(":id", itemId)}`,
        quantityData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Error updating item quantity:", error);
      throw error;
    }
  },

  deleteItemFromCart: async function (itemId) {
    try {
      const response = await axios.patch(
        `${urls.deleteItemFromCart.replace(":id", itemId)}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Error deleting item from cart:", error);
      throw error;
    }
  },

  addItem: async function (itemData) {
    try {
      const response = await axios.post(urls.addItem, itemData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error adding item to cart:", error);
      throw error;
    }
  },

  clearCart: async function () {
    try {
      const response = await axios.post(urls.clearCart, null, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error clearing cart:", error);
      throw error;
    }
  },

  // Address
  createAddress: async function (addressData) {
    try {
      const response = await axios.post(urls.createAddress, addressData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error creating address:", error);
      throw error;
    }
  },

  getAddressById: async function (id) {
    try {
      const response = await axios.get(urls.getAddressById.replace(":id", id), {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error fetching address by ID:", error);
      throw error;
    }
  },

  getAddressesByUser: async function () {
    try {
      const response = await axios.get(urls.getAddressesByUser, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error fetching addresses by user ID:", error);
      throw error;
    }
  },

  updateAddress: async function (id, updatedData) {
    try {
      const response = await axios.put(
        urls.updateAddress.replace(":id", id),
        updatedData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Error updating address:", error);
      throw error;
    }
  },

  deleteAddress: async function (id) {
    try {
      const response = await axios.delete(
        urls.deleteAddress.replace(":id", id),
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Error deleting address:", error);
      throw error;
    }
  },

  // Apply Discount
  applyDiscount: async function (data) {
    try {
      const response = await axios.post(urls.applyDiscount, data, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error Apply Discount:", error);
      throw error;
    }
  },

  // Apply Discount
  getAllCategories: async function () {
    try {
      const response = await axios.get(urls.getAllCategories, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error Apply Discount:", error);
      throw error;
    }
  },

  // Payment
  payment: async function (data) {
    try {
      const response = await axios.post(urls.payment, data, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Checking Payment:", error);
      throw error;
    }
  },

  getAllOrder: async function () {
    try {
      const response = await axios.get(urls.getAllOrder, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error fetching addresses by user ID:", error);
      throw error;
    }
  },

  getSizesByCategoryId: async function (categoryId) {
    try {
      const response = await axios.get(`${urls.sizes}/${categoryId}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error fetching sizes by category ID:", error);
      throw error;
    }
  },
};
