import { useParams } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/NavBar";
import { Products } from "../components/Products";

export const Shop = () => {
  const { categoryId, catergoryName } = useParams();
  return (
    <>
      <Navbar />
      <Products categoryId={categoryId} catergoryName={catergoryName} />
      <Footer />
    </>
  );
};
