import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../components/css/banner.css";
import { apiConfig } from "../utils/apiConfig";
import { ShopByCategorySkeleton } from "./skeleton/ShopByCategorySkeleton";
import { Link } from "react-router-dom";

const categories = [
  {
    id: 1,
    name: "Oversized T-shirts",
    imgSrc:
      "https://images.bewakoof.com/uploads/grid/app/444x666-Trending-Category-Icon-Desktop--1--1729005516.jpg",
  },
  {
    id: 2,
    name: "Hoodies",
    imgSrc:
      "https://images.bewakoof.com/uploads/grid/app/444x666-Trending-Category-Icon-Desktop-11-1729005533.jpg",
  },
  {
    id: 3,
    name: "Classic Fit T-shirts",
    imgSrc:
      "https://images.bewakoof.com/uploads/grid/app/444x666-Trending-Category-Icon-Desktop-6-1729005517.jpg",
  },
  {
    id: 4,
    name: "Sweatshirts",
    imgSrc:
      "https://images.bewakoof.com/uploads/grid/app/444x666-Trending-Category-Icon-desktop-7-1729005517.jpg",
  },
  {
    id: 5,
    name: "Joggers",
    imgSrc:
      "https://images.bewakoof.com/uploads/grid/app/444x666-Trending-Category-Icon-Classic-Fit-Tshirts-3-1729005515.jpg",
  },
  {
    id: 6,
    name: "Jeans",
    imgSrc:
      "https://images.bewakoof.com/uploads/grid/app/444x666-Trending-Category-Icon-Desktop-Fullsleeve-T-shirts-Men-1729595808.jpg",
  },
];

const ShopByCategory = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true); // State to manage loading status

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await apiConfig.getAllCategories(); // Replace with your actual API endpoint
        setCategories(
          response?.data?.data?.filter((e) => e.categoryActive === true)
        ); // Assuming response.data contains the array of products
        setLoading(false); // Set loading to false after fetching
      } catch (err) {
        console.error("Error fetching products:", err);
        setLoading(false); // Set loading to false on error
      }
    };

    fetchProducts();
  }, []); // Empty dependency a

  return (
    <>
      {loading ? (
        <ShopByCategorySkeleton />
      ) : (
        <div className="custom-shop-container my-5">
          <h4 className="">Shop by Category</h4>

          <div className="d-none d-md-flex row mt-5">
            {categories?.map((category) => (
              <div
                key={category.id}
                className="col-md-4 col-lg-2 mb-4"
                style={{ position: "relative" }}
              >
                <Link
                  to={`/category/${category?.categoryName}/${category?._id}`}
                >
                  <div className="image-container">
                    <img
                      src={category.image}
                      alt={category.name}
                      className="img-fluid rounded"
                    />
                  </div>
                </Link>
                <p className="mt-2 shop-check">{category.categoryName}</p>
              </div>
            ))}
          </div>

          <div className="d-md-none mt-4">
            <div className="scroll-container" style={{ position: "relative" }}>
              {categories?.map((category) => (
                <>
                  <div style={{ position: "relative" }}>
                    <Link
                      to={`/category/${category?.categoryName}/${category?._id}`}
                    >
                      <div className="scroll-item image-container">
                        <img
                          src={category.image}
                          alt={category.categoryName}
                          className="img-fluid rounded"
                        />
                      </div>
                    </Link>
                    <p className="mt-2 shop-check">{category.categoryName}</p>
                  </div>
                </>
              ))}
            </div>
          </div>
          {/* Desktop view (grid layout)  */}
          {/* <div className="d-none d-md-flex row mt-3">
                {categories.map(category => (
                    <div key={category.id} className="col-md-4 col-lg-2 mb-4 text-center">
                        <img 
                            src={category.imgSrc} 
                            alt={category.name} 
                            className="img-fluid rounded"
                        />
                    <p className="mt-2 fw-bold">{category.name}</p>
                    </div>
                ))}
            </div> */}
          {/* //  Mobile view (Swiper slider)  */}

          {/* <div className="d-md-none mt-3">
                <div className="scroll-container">
                    {categories.map((category) => (
                        <div key={category.id} className="scroll-item text-center">
                            <img 
                                src={category.imgSrc} 
                                alt={category.name} 
                                className="img-fluid rounded"
                            />
                            <p className="mt-2 fw-bold">{category.name}</p>
                        </div>
                    ))}
                </div>
            </div> */}
        </div>
      )}
    </>
  );
};

export default ShopByCategory;
