import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";

const OrderModal = ({
  open,
  onClose,
  addresses,
  setAddress,
  handleProceedOrder,
}) => {
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const navigate = useNavigate();

  const handleSelectAddress = (addressId) => {
    setSelectedAddressId(addressId);
    setAddress(addressId);
  };

  const handleEdit = (id) => {
    // Navigate to the edit page, passing the address id as a state parameter
    navigate(`/my-account/addresses/add`, { state: { id } });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", sm: 500, md: 600 }, // Responsive width
          bgcolor: "white",
          borderRadius: "10px",
          boxShadow: 24,
          p: { xs: 2, sm: 3 }, // Responsive padding
        }}
      >
        {/* Title */}
        <Typography
          variant="h6"
          sx={{
            fontWeight: 600,
            marginBottom: 3,
            color: "black",
            fontSize: { xs: "1rem", sm: "1.25rem" }, // Responsive font size
          }}
        >
          Select Delivery Address
        </Typography>

        {/* Address List */}
        <RadioGroup
          value={selectedAddressId}
          onChange={(e) => handleSelectAddress(e.target.value)}
        >
          {addresses.map((address) => (
            <Box
              key={address._id}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" }, // Stack on mobile
                alignItems: "flex-start",
                justifyContent: "space-between",
                border: "1px solid #ddd",
                borderRadius: 2,
                padding: 2,
                marginBottom: 2,
              }}
            >
              <FormControlLabel
                value={address._id}
                control={
                  <Radio
                    sx={{
                      color: "black",
                      "&.Mui-checked": {
                        color: "black",
                      },
                    }}
                  />
                }
                sx={{ flex: 1 }}
                label={
                  <Box>
                    <Typography
                      variant="subtitle1"
                      fontWeight="600"
                      sx={{ color: "black" }}
                    >
                      {address.firstName} {address.lastName}
                      <Box
                        component="span"
                        sx={{
                          marginLeft: 2,
                          color: "white",
                          backgroundColor: "#ffcc00",
                          padding: "2px 8px",
                          borderRadius: "5px",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        {address.addressType}
                      </Box>
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "black", marginTop: 1 }}
                    >
                      {address.street} {address.city} {address.state}{" "}
                      {address.postalCode}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "black", marginTop: 0.5 }}
                    >
                      Contact number: {address.phone}
                    </Typography>
                  </Box>
                }
              />

              <Button
                size="small"
                startIcon={<EditIcon />}
                sx={{
                  textTransform: "none",
                  fontSize: "0.8rem",
                  fontWeight: 500,
                  color: "black",
                  marginTop: { xs: 1, sm: 0 }, // Add margin on mobile
                }}
                onClick={() => handleEdit(address._id)}
              >
                Edit
              </Button>
            </Box>
          ))}
        </RadioGroup>

        {/* Footer */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" }, // Stack on mobile
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 3,
          }}
        >
          <Button
            variant="text"
            onClick={() => navigate("/my-account/addresses/add")}
            sx={{
              fontWeight: 600,
              textTransform: "none",
              color: "black",
              marginBottom: { xs: 2, sm: 0 }, // Add margin on mobile
            }}
          >
            + Add New Address
          </Button>
          <Button
            variant="contained"
            sx={{
              padding: "10px 20px",
              fontWeight: 600,
              textTransform: "none",
              backgroundColor: "black",
              color: "white",
              "&:hover": {
                backgroundColor: "#333",
              },
            }}
            onClick={handleProceedOrder}
            disabled={!selectedAddressId}
          >
            Proceed to Payment
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default OrderModal;
