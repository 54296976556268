import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import {
  Logout,
  LocationOn,
  AccountCircle,
  ShoppingCart,
  Payment,
  HelpOutline,
} from "@mui/icons-material";
import InboxIcon from "@mui/icons-material/Inbox";
import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";
import { MyAccountOverview } from "../components/MyAccount/MyAccountOverview";
import OrderHistory from "../components/MyAccount/OrderHistory";
import AddressManager from "../components/MyAccount/AddressManager";
import Profile from "../components/MyAccount/Profile";
import { AddAddress } from "../components/MyAccount/AddAddress";
import MoreDetails from "../components/MyAccount/MoreDetails";

const MyAccount = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedMenu, setSelectedMenu] = useState("Overview"); // State for selected menu

  const renderContent = () => {
    switch (selectedMenu) {
      case "My Orders":
        return (
          <Box>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              My Orders
            </Typography>
            <Typography>View, track, and manage your orders here.</Typography>
          </Box>
        );
      case "My Payments":
        return (
          <Box>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              My Payments
            </Typography>
            <Typography>
              Manage your payment methods and view transactions.
            </Typography>
          </Box>
        );
      case "My Addresses":
        return (
          <Box>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              My Addresses
            </Typography>
            <Typography>View and manage your saved addresses here.</Typography>
          </Box>
        );
      case "My Profile":
        return (
          <Box>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              My Profile
            </Typography>
            <Typography>Edit your personal details and preferences.</Typography>
          </Box>
        );
      case "Logout":
        return (
          <Box>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", mb: 2, color: "red" }}
            >
              Logged Out
            </Typography>
            <Typography>
              You have been logged out. See you again soon!
            </Typography>
          </Box>
        );
      default:
        return (
          <Typography variant="body1">
            Select an option from the menu.
          </Typography>
        );
    }
  };

  return (
    <>
      <Navbar />
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          marginTop: "110px",
          marginBottom: "50px",
          marginX: { xs: "16px", md: "80px" },
        }}
      >
        {/* Side Navigation */}
        <Box
          sx={{
            width: { xs: "100%", md: "300px" },
            bgcolor: "#fff",
            border: "1px solid #ddd",
            borderRadius: "8px",
            padding: "20px",
            marginBottom: { xs: 2, md: 0 },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              marginBottom: 3,
              fontWeight: "bold",
              color: "#000",
            }}
          >
            Overview
          </Typography>
          <List>
            {[
              { icon: <InboxIcon />, text: "Overview", route: "/my-account" },
              {
                icon: <ShoppingCart />,
                text: "My Orders",
                route: "/my-account/orders",
              },
              { icon: <Payment />, text: "My Payments", route: "payments" },
              {
                icon: <LocationOn />,
                text: "My Addresses",
                route: "/my-account/addresses",
              },
              {
                icon: <AccountCircle />,
                text: "My Profile",
                route: "/my-account/profile",
              },
              {
                icon: <Logout sx={{ color: "red" }} />,
                text: "Logout",
                route: "logout",
              },
            ].map((item, index) => (
              <ListItem
                key={index}
                button
                onClick={() => navigate(item.route)} // Update state on click
                sx={{
                  cursor: "pointer",
                  backgroundColor:
                    selectedMenu === item.text ? "#f5f5f5" : "transparent",
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                  },
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                  primary={item.text}
                  primaryTypographyProps={{
                    fontWeight: selectedMenu === item.text ? "bold" : "normal",
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Box>

        {/* Main Content */}
        <Box
          sx={{
            flexGrow: 1,
            paddingLeft: { xs: 0, md: "20px" },
            width: "100%",
          }}
        >
          {/* {renderContent()} */}
          {location.pathname === "/my-account" && <MyAccountOverview />}
          {location.pathname === "/my-account/orders" && <OrderHistory />}
          {location.pathname === "/my-account/addresses" && <AddressManager />}
          {location.pathname === "/my-account/profile" && <Profile />}
          {location.pathname === "/my-account/addresses/add" && <AddAddress />}
          {location.pathname === "/my-account/order/moreInfo" && (
            <MoreDetails />
          )}
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default MyAccount;
