import React, { useEffect, useState } from "react";
import { Typography, Box, Grid, Divider, List, ListItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { apiConfig } from "../../utils/apiConfig";
import { useNavigate } from "react-router-dom";

const OrderHistory = () => {
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate(); // Initialize the navigate function

  // Handle navigation on item click
  const handleProductClick = (item) => {
    console.log(item, "checking");
    navigate("/my-account/order/moreInfo", { state: { orderDetail: item } }); // Passing 'item' as state
  };
  useEffect(() => {
    // Fetch orders data from the API
    const fetchOrders = async () => {
      try {
        const response = await apiConfig.getAllOrder(); // Update with your API endpoint
        setOrders(response?.data?.data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, []);
  return (
    <Box sx={{ padding: 1.5, borderRadius: 2, border: "1px solid #ddd" }}>
      {/* Title */}
      {/* Products List */}
      {orders.map((order) => (
        <Box key={order.orderId} onClick={() => handleProductClick(order)}>
          {" "}
          {/* Added key for map iteration */}
          <List>
            {order.products.map((item, index) => (
              <ListItem
                key={index}
                disableGutters // Trigger navigation on click
                sx={{ cursor: "pointer" }}
              >
                <Box
                  sx={{
                    width: "100%",
                    border: "1px solid #ddd",
                    borderRadius: 2,
                    padding: 1.3,
                    marginBottom: 2,
                  }}
                >
                  <Grid container spacing={5} alignItems="center">
                    {/* Product Image */}
                    <Grid item xs={3}>
                      <div style={{ width: "50%" }}>
                        <figure style={{ height: "100%", display: "flex" }}>
                          <img
                            src={item?.product?.primaryImageUrl}
                            alt="Product"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover", // Ensures image covers the area while maintaining aspect ratio
                            }}
                          />
                        </figure>
                      </div>
                    </Grid>

                    {/* Product Details */}
                    <Grid item xs={8}>
                      <p>{item?.product?.productName}</p>
                      <Grid container spacing={2} sx={{ marginTop: 1 }}>
                        <Grid item xs={4}>
                          <Typography variant="body2">
                            <strong>Price:</strong> ₹
                            {item?.product?.sellingPrice}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body2">
                            <strong>Quantity:</strong> {item?.quantity}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body2">
                            <strong>Size:</strong> {item?.size}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid xs={1}>
                      <Typography variant="subtitle1" sx={{ fontSize: "20px" }}>
                        {">"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </ListItem>
            ))}
          </List>
        </Box>
      ))}
    </Box>
  );
};

export default OrderHistory;
