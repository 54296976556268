import Banner from "../components/Banner";
import Footer from "../components/Footer";
import Navbar from "../components/NavBar";
import { Products } from "../components/Products";
import ShopByCategory from "../components/ShopByCategory";
import Slider from "../components/Slider";

export const MainPage = () => {
  return (
    <>
      <div>
        <Navbar />
        <Slider />
        <ShopByCategory />
        {/* <Banner /> */}
        <Products />
        <Footer />
      </div>
    </>
  );
};
