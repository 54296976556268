import React from "react";
import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import "./css/policy.css";

const TermsAndConditions = () => {
  return (
    <>
      <Navbar />
      <div
        className="container terms"
        style={{ marginTop: "110px", marginBottom: "50px" }}
      >
        <h5>Terms & Conditions</h5>
        <p>
          This document is an electronic record in terms of the Information
          Technology Act, 2000, and the rules thereunder as applicable, along
          with amended provisions pertaining to electronic records in various
          statutes as amended by the Information Technology Act, 2000. This
          electronic record is generated by a computer system and does not
          require any physical or digital signatures.
        </p>
        <p>
          This document is published in accordance with the provisions of Rule 3
          (1) of the Information Technology (Intermediaries guidelines) Rules,
          2011, which require the publishing of rules and regulations, privacy
          policy, and Terms of Use for access or usage of the domain name{" "}
          <a
            href="https://www.themonkeysoul.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.themonkeysoul.com
          </a>
          ("Website"), including the related mobile site and mobile application
          (hereinafter referred to as "Platform").
        </p>
        <p>
          The Platform is owned by **JH Fashion**, the parent company of
          TheMonkeySoul, a company incorporated under applicable laws, with its
          registered office at [JH Fashion's Company Address] (hereinafter
          referred to as "Platform Owner," "we," "us," or "our").
        </p>
        <p>
          Your use of the Platform, services, and tools is governed by the
          following terms and conditions ("Terms of Use") as applicable to the
          Platform, including the applicable policies which are incorporated
          herein by reference. If you transact on the Platform, you shall be
          subject to the policies that are applicable to the Platform for such
          transactions. By mere use of the Platform, you shall be contracting
          with the Platform Owner, and these Terms and Conditions, including the
          policies, constitute your binding obligations with the Platform Owner.
          These Terms of Use apply to your use of our website, goods, or
          services (collectively, "Services").
        </p>
        <p>
          Any terms and conditions proposed by you which are in addition to or
          conflict with these Terms of Use are expressly rejected by the
          Platform Owner and shall be of no force or effect. These Terms of Use
          can be modified at any time without assigning any reason. It is your
          responsibility to periodically review these Terms of Use to stay
          informed of updates.
        </p>
        <p>
          For the purpose of these Terms of Use, wherever the context so
          requires, "you," "your," or "user" shall mean any natural or legal
          person who has agreed to become a user/buyer on the Platform.
        </p>
        <p style={{ fontWeight: "bold" }}>
          ACCESSING, BROWSING, OR OTHERWISE USING THE PLATFORM INDICATES YOUR
          AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF USE, SO
          PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING.
        </p>

        <h6>1. Access and Use of Services</h6>
        <p>
          To access and use the Services, you agree to provide true, accurate,
          and complete information to us during and after registration, and you
          shall be responsible for all acts done through the use of your
          registered account on the Platform.
        </p>

        <h6>2. Disclaimer of Warranties</h6>
        <p>
          Neither we nor any third parties provide any warranty or guarantee as
          to the accuracy, timeliness, performance, completeness, or suitability
          of the information and materials offered on this website or through
          the Services, for any specific purpose. You acknowledge that such
          information and materials may contain inaccuracies or errors, and we
          expressly exclude liability for any such inaccuracies or errors to the
          fullest extent permitted by law.
        </p>

        <h6>3. User Responsibility</h6>
        <p>
          Your use of our Services and the Platform is solely and entirely at
          your own risk and discretion for which we shall not be liable to you
          in any manner. You are required to independently assess and ensure
          that the Services meet your requirements.
        </p>

        <h6>4. Intellectual Property Rights</h6>
        <p>
          The contents of the Platform and the Services are proprietary to us
          and are licensed to us. You will not have any authority to claim any
          intellectual property rights, title, or interest in its contents. The
          contents include, but are not limited to, the design, layout, look,
          and graphics.
        </p>

        <h6>5. Unauthorized Use</h6>
        <p>
          You acknowledge that unauthorized use of the Platform and/or the
          Services may lead to action against you as per these Terms of Use
          and/or applicable laws.
        </p>

        <h6>6. Payment of Charges</h6>
        <p>
          You agree to pay us the charges associated with availing the Services.
        </p>

        <h6>7. Legal Compliance</h6>
        <p>
          You agree not to use the Platform and/or Services for any purpose that
          is unlawful, illegal, or forbidden by these Terms, or Indian or local
          laws that might apply to you.
        </p>

        <h6>8. Third-Party Links</h6>
        <p>
          You agree and acknowledge that the website and the Services may
          contain links to other third-party websites. On accessing these links,
          you will be governed by the terms of use, privacy policy, and such
          other policies of such third-party websites. These links are provided
          for your convenience to provide further information.
        </p>

        <p>
          For any queries regarding these Terms and Conditions, please contact
          us at support@themonkeysoul.com.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
