import React from "react";
import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import "./css/policy.css";
const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <div
        className="container"
        style={{ marginTop: "110px", marginBottom: "50px" }}
      >
        <h5>Privacy Policy</h5>
        <p>
          This Privacy Policy describes how **JH Fashion** and its brand
          **TheMonkeySoul** (collectively referred to as “we,” “our,” “us”)
          collect, use, share, protect, or otherwise process your
          information/personal data through our website
          <a
            href="https://www.themonkeysoul.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            https://www.themonkeysoul.com
          </a>{" "}
          (hereinafter referred to as “Platform”). Please note that you may be
          able to browse certain sections of the Platform without registering
          with us.
        </p>
        <p>
          We do not offer any product/service on this Platform outside of India,
          and your personal data will primarily be stored and processed in
          India. By visiting this Platform, providing your information, or
          availing any product/service offered on the Platform, you expressly
          agree to be bound by the terms and conditions of this Privacy Policy,
          our Terms of Use, and other applicable service/product terms and
          conditions, and agree to be governed by the laws of India, including
          those applicable to data protection and privacy. If you do not agree,
          please refrain from using or accessing our Platform.
        </p>

        <h6>Collection of Information</h6>
        <p>
          We collect your personal data when you use our Platform, services, or
          otherwise interact with us. The information collected may include, but
          is not limited to:
          <ul>
            <li>
              Personal details such as name, date of birth, address,
              telephone/mobile number, email ID.
            </li>
            <li>
              Identification information provided during sign-up/registration,
              including proof of identity or address.
            </li>
            <li>
              Sensitive personal data, with your consent, such as payment
              information (e.g., bank account or card details) and biometric
              information (e.g., facial or physiological information).
            </li>
          </ul>
          You always have the option to choose not to provide information by not
          using specific services or features on the Platform. We may also track
          your behavior, preferences, and other information that you choose to
          provide on the Platform.
        </p>

        <p>
          Additionally, we may collect information related to your transactions
          on the Platform and on third-party partner platforms. Please note that
          if any third-party business partner collects personal data directly
          from you, you will be governed by their privacy policies. We are not
          responsible for the privacy practices or content of third-party
          privacy policies, and we encourage you to read them before disclosing
          any information.
        </p>

        <h6>Usage of Information</h6>
        <p>
          We use your personal data to provide services requested by you.
          Additionally, we may use it for purposes such as:
          <ul>
            <li>
              Fulfilling orders and handling payments through third-party
              service providers.
            </li>
            <li>
              Enhancing the customer experience, customizing services, and
              resolving disputes.
            </li>
            <li>Notifying you about offers, products, and updates.</li>
            <li>Conducting marketing research, surveys, and analytics.</li>
            <li>
              Ensuring compliance with our terms, detecting fraud, and
              maintaining security.
            </li>
          </ul>
          To the extent we use your personal data for marketing purposes, you
          will have the option to opt out.
        </p>

        <h6>Sharing of Information</h6>
        <p>
          We may share your personal data internally within our corporate
          entities and affiliates to provide you access to services and products
          offered by them. These affiliates may market their services to you
          unless you opt out.
        </p>
        <p>
          Additionally, we may disclose personal data to third parties,
          including:
          <ul>
            <li>Sellers and business partners.</li>
            <li>
              Third-party service providers (e.g., logistics partners, payment
              processors, marketing partners).
            </li>
            <li>
              Government and law enforcement agencies as required by law, or if
              disclosure is necessary to comply with legal obligations, prevent
              fraud, or protect our rights and users’ safety.
            </li>
          </ul>
        </p>

        <p>
          We may share aggregated information (without direct identification of
          users) with our partners, advertisers, and other third parties to
          develop content and services aligned with your interests.
        </p>

        <h6>Security Precautions</h6>
        <p>
          To protect your personal data from unauthorized access or disclosure,
          loss, or misuse, we adopt reasonable security practices and
          procedures. Once your information is in our possession or whenever you
          access your account information, we adhere to our security guidelines
          to protect it against unauthorized access and offer the use of a
          secure server.
        </p>
        <p>
          However, the transmission of information over the internet is not
          completely secure for reasons beyond our control. By using the
          Platform, you accept the security implications of data transmission
          over the internet and the World Wide Web, which cannot always be
          guaranteed as completely secure. There will always remain certain
          inherent risks regarding the use of the Platform. You are responsible
          for ensuring the protection of login and password records for your
          account.
        </p>

        <h6>Data Deletion and Retention</h6>
        <p>
          You have the option to delete your account by visiting your profile
          and settings on our Platform. This action would result in you losing
          all information related to your account. You may also write to us at
          the contact information provided below to assist you with these
          requests.
        </p>
        <p>
          In the event of any pending grievance, claims, pending shipments, or
          other services, we may refuse or delay deletion of the account. Once
          the account is deleted, you will lose access to the account. We retain
          your personal data for a period no longer than is required for the
          purpose for which it was collected or as required under applicable
          law. However, we may retain data related to you if we believe it may
          be necessary to prevent fraud or future abuse or for other legitimate
          purposes. We may continue to retain your data in anonymized form for
          analytical and research purposes.
        </p>

        <h6>Your Rights</h6>
        <p>
          You may access, rectify, and update your personal data directly
          through the functionalities provided on the Platform. You can also
          contact us to exercise these rights by writing to us at the contact
          information provided below.
        </p>

        <h6>Consent</h6>
        <p>
          By visiting our Platform or providing your information, you consent to
          the collection, use, storage, disclosure, and processing of your
          information in accordance with this Privacy Policy. If you disclose
          any personal data to us related to other people, you represent that
          you have the authority to do so and permit us to use the information
          in accordance with this Privacy Policy.
        </p>
        <p>
          While providing your personal data over the Platform or any partner
          platforms or establishments, you consent to us (including our
          corporate entities, affiliates, lending partners, technology partners,
          marketing channels, business partners, and third parties) contacting
          you through SMS, instant messaging apps, calls, and/or email for the
          purposes specified in this Privacy Policy. You have the option to
          withdraw your consent by writing to the Grievance Officer at the
          contact information provided below. Please mention "Withdrawal of
          consent for processing personal data" in your subject line.
        </p>
        <p>
          Please note that your withdrawal of consent will not be retrospective
          and will be in accordance with the Terms of Use, this Privacy Policy,
          and applicable laws. If you withdraw consent, we reserve the right to
          restrict or deny services for which we consider such information
          necessary.
        </p>

        <h6>Changes to this Privacy Policy</h6>
        <p>
          Please check our Privacy Policy periodically for changes. We may
          update this Privacy Policy to reflect changes to our information
          practices. We will notify you about significant changes as required by
          applicable law.
        </p>

        <p>
          For any questions or concerns regarding this Privacy Policy, please
          contact us at
          <a href="mailto:support@themonkeysoul.com">
            {" "}
            support@themonkeysoul.com
          </a>
          .
        </p>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
