import React, { useEffect, useState } from "react";
import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import { Snackbar, Alert } from "@mui/material";
import ReactImageMagnify from "react-image-magnify";
import "./css/product-overview.css"; // Ensure CSS for styling
import { apiConfig } from "../utils/apiConfig";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import ProductOverviewSkeleton from "./skeleton/ProductOverviewSkeleton";
import { useDispatch } from "react-redux";
import { addToCart } from "../store/actions/cartActions";
import Swal from "sweetalert2";

const ProductOverview = () => {
  // State to track the current main image
  const [mainImage, setMainImage] = useState();
  const [secondImage, setSecondImage] = useState([]);
  const dispatch = useDispatch();
  const { productId } = useParams(); // Get product ID from URL params
  const [sizes, setSizes] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate
  const [product, setProduct] = useState(null); // State to store the product
  const [similarProduct, setSimilarProduct] = useState([]);
  const [loading, setLoading] = useState(true); // State to manage loading status
  const [btnLoading, setBtnLoading] = useState(true);
  const [selectSize, setSelectSize] = useState("XS");
  const [quantity, setQuantity] = useState(1);
  const availableSizes = ["XS", "S", "M", "L", "XL", "XXL"];
  const [sizeData, setSizeData] = useState([]);
  const [selectedMeasurements, setSelectedMeasurements] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Snackbar state
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    setLoading(true);
    const loggedInStatus = localStorage.getItem("isLoggedIn") === "true";
    setIsLoggedIn(loggedInStatus);
    const fetchProduct = async () => {
      try {
        const response = await apiConfig.getProductById(productId); // Fetch product by ID
        setProduct(response?.data?.data); // Assuming response.data contains the product object
        setMainImage(response?.data?.data?.primaryImageUrl);
        setSecondImage([
          response?.data?.data?.primaryImageUrl,
          ...(Array.isArray(response?.data?.data?.secondaryImageUrl)
            ? response.data.data.secondaryImageUrl
            : []),
        ]);

        const categoryData = response?.data?.data?.categoryId; // Assume category data is returned directly
        setSizes(categoryData.sizes);

        const sizeCategory = await apiConfig.getSizesByCategoryId(
          categoryData?._id
        );
        setSizeData(sizeCategory?.data?.sizes);

        // Set sizes from backend response
        const similarProd = await apiConfig.getSimilarProduct({
          productId: productId,
          categoryId: response?.data?.data?.categoryId?._id,
        });
        console.log(similarProd?.data, "Checking");
        setSimilarProduct(similarProd?.data);
        setLoading(false); // Set loading to false after fetching
      } catch (err) {
        console.error("Error fetching product:", err);
        setLoading(false); // Set loading to false on error
      }
    };

    fetchProduct();
  }, [productId]); // Dependency array includes id to refetch if it changes

  // Debounced function to update item details

  const handleSizeChange = (size, unit = "inch") => {
    setSelectSize(size);
    // Find measurements based on selected unit (inch or cm)
    const selectedSizeData = sizeData?.find((s) => s.size === size);

    const measurements = selectedSizeData?.measurements?.find(
      (m) => m.unit === unit
    );
    setSelectedMeasurements(measurements);
  };

  const handleQuantityChange = (type) => {
    setQuantity((prevQuantity) =>
      type === "increase" ? prevQuantity + 1 : Math.max(prevQuantity - 1, 1)
    );
  };

  const handleBuynow = async () => {
    setBtnLoading(false);
    if (!isLoggedIn) {
      setBtnLoading(true);
      setError("Please log in to proceed with the purchase.");
      // navigate('/sign-in'); // Redirect to login if not logged in
      return;
    }

    const response = await apiConfig.addItem({
      productId,
      quantity,
      size: selectSize,
    });
    console.log(response, "Checking");
    if (response?.status === 201) {
      setBtnLoading(true);
      navigate("/cart");
    }
  };

  const handleAddToCart = async () => {
    setBtnLoading(false);
    if (!isLoggedIn) {
      setBtnLoading(true);
      setError("Please log in to add items to your cart.");
      // navigate('/sign-in'); // Redirect to login if not logged in
      return;
    }

    const response = await apiConfig.addItem({
      productId,
      quantity,
      size: selectSize,
    });
    if (response?.status === 201) {
      dispatch(addToCart(response?.data?.data));
      setBtnLoading(true);
      // setSuccess("Product Added Successfully to cart");
      Swal.fire({
        icon: "success",
        title: "Added to Cart",
        text: "The product was successfully added to your cart!",
        confirmButtonText: "Continue Shopping",
      }).then((result) => {
        if (result.isConfirmed) {
          // Navigate to the /shop page when the confirm button is clicked
          navigate("/cart");
        }
      });
      // const totalAmount = addToCart(response?.data);
    }
  };

  // Conditional rendering based on loading and error states

  // Array of thumbnail images
  const thumbnailImages = [
    "https://mdbcdn.b-cdn.net/img/bootstrap-ecommerce/items/detail1/big1.webp",
    "https://mdbcdn.b-cdn.net/img/bootstrap-ecommerce/items/detail1/big2.webp",
    "https://mdbcdn.b-cdn.net/img/bootstrap-ecommerce/items/detail1/big3.webp",
    "https://mdbcdn.b-cdn.net/img/bootstrap-ecommerce/items/detail1/big4.webp",
    "https://mdbcdn.b-cdn.net/img/bootstrap-ecommerce/items/detail1/big.webp",
  ];

  return (
    <>
      <Navbar />
      <section
        className="py-5"
        style={{ overflow: "hidden", marginTop: "70px" }}
      >
        <div className="container">
          <div className="row gx-5">
            {loading ? (
              <>
                <ProductOverviewSkeleton />
              </>
            ) : (
              <>
                <aside className="col-lg-6 d-flex">
                  {/* Thumbnail images aligned vertically on the left */}
                  <div className="d-flex flex-column align-items-center me-3">
                    {secondImage?.map((image, index) => (
                      <a
                        key={index}
                        onClick={() => setMainImage(image)}
                        className="border mb-2 item-thumb"
                        href="#"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          width="60"
                          height="60"
                          className=""
                          src={image}
                          alt={`Thumbnail ${index + 1}`}
                        />
                      </a>
                    ))}
                  </div>
                  <div className="mb-3 d-flex justify-content-center">
                    <ReactImageMagnify
                      {...{
                        smallImage: {
                          alt: "Main Product",
                          isFluidWidth: true,
                          src: mainImage,
                          className: "rounded-4 fit",
                        },
                        largeImage: {
                          src: mainImage,
                          width: 1200, // Adjust based on your high-resolution image
                          height: 1800,
                        },
                        enlargedImagePosition: "over", // Position of magnified image
                      }}
                    />
                  </div>
                </aside>

                <main className="col-lg-6">
                  {/* Product details */}
                  <div className="ps-lg-3">
                    <h4 className="title text-dark">{product?.productName}</h4>
                    <div className="d-flex flex-row my-3">
                      <div className="text-warning mb-1 me-2">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fas fa-star-half-alt"></i>
                        <span className="ms-1">4.5</span>
                      </div>
                      <span className="text-muted">
                        <i className="fas fa-shopping-basket fa-sm mx-1"></i>154
                        orders
                      </span>
                      <span className="text-success ms-2">In stock</span>
                    </div>

                    <div className="mb-3">
                      <span>
                        <span>{`₹${product?.sellingPrice}`}</span>&nbsp; &nbsp;
                        <span
                          style={{
                            textDecoration: "line-through",
                            marginRight: 5,
                            fontSize: "14px",
                            color: "grey",
                          }}
                        >{`₹${product?.price}`}</span>
                      </span>
                      <span
                        style={{ color: "green", marginLeft: 10 }}
                      >{`${Math.round(
                        ((product?.price - product?.sellingPrice) /
                          product?.price) *
                          100
                      )}% off`}</span>
                    </div>

                    <p>{product?.description}</p>

                    <hr />

                    <div className="row mb-4">
                      <div className="col-md-8">
                        <label className="mb-2 d-block">Select Size</label>
                        <div className="d-flex">
                          {availableSizes.map((size, index) => (
                            <button
                              key={index}
                              onClick={() => handleSizeChange(size)}
                              className={`btn border me-2 ${
                                selectSize === size
                                  ? "btn-outline-primary selected"
                                  : "btn-outline-secondary"
                              }`}
                              disabled={!sizes.includes(size)}
                              style={{
                                minWidth: "50px",
                                color: sizes.includes(size) ? "black" : "grey",
                                backgroundColor:
                                  selectSize === size
                                    ? "lightgray"
                                    : "transparent", // Change background color to gray when selected
                              }}
                            >
                              {size}
                            </button>
                          ))}
                        </div>
                        {/* {selectSize === "XL" && (
                          <div className="text-danger mt-1">2 left</div>
                        )} */}
                        {selectSize && selectedMeasurements && (
                          <div className="mt-3">
                            <span style={{ fontSize: "small" }}>Garment:</span>
                            <span style={{ fontSize: "small", color: "grey" }}>
                              {" "}
                              Chest ({"in inch"}):{" "}
                              <strong style={{ color: "black" }}>
                                {selectedMeasurements.chest}
                              </strong>{" "}
                              | Front Length ({"in inch"}):{" "}
                              <strong style={{ color: "black" }}>
                                {selectedMeasurements.frontLength}
                              </strong>{" "}
                              | Sleeve Length ({"in inch"}):{" "}
                              <strong style={{ color: "black" }}>
                                {selectedMeasurements.sleeveLength}
                              </strong>
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="action-buttons">
                      <Link
                        className="btn-overview add-to-cart"
                        onClick={handleAddToCart}
                      >
                        ADD TO CART
                      </Link>

                      <Link
                        className="btn-overview buy-now"
                        onClick={handleBuynow}
                      >
                        <span>BUY NOW</span>
                        <span className="arrow">→</span>
                      </Link>

                      {/* <Link
    className="btn add-to-wishlist"
    onClick={handleAddToWishlist}
  >
    <span>♡ ADD TO WISHLIST</span>
  </Link> */}
                    </div>
                    <div class="container product-details my-4">
                      <div class="mb-4">
                        <h4>Highlights</h4>
                        <div class="row">
                          <div class="col-6">
                            <p>
                              <strong>Design</strong>
                              <br />
                              Graphic Print
                            </p>
                          </div>
                          <div class="col-6">
                            <p>
                              <strong>Fit</strong>
                              <br />
                              Oversized Fit
                            </p>
                          </div>
                          <div class="col-6">
                            <p>
                              <strong>Neck</strong>
                              <br />
                              Round Neck
                            </p>
                          </div>
                          <div class="col-6">
                            <p>
                              <strong>Occasion</strong>
                              <br />
                              Casual Wear
                            </p>
                          </div>
                          <div class="col-6">
                            <p>
                              <strong>Sleeve Style</strong>
                              <br />
                              Full Sleeve
                            </p>
                          </div>
                          <div class="col-6">
                            <p>
                              <strong>Wash Care</strong>
                              <br />
                              Machine wash as per tag
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="d-flex justify-content-around mt-4 pt-3 border-top">
                        <div class="text-center">
                          <i class="fas fa-shield-alt fa-2x text-warning mb-2"></i>
                          <p>100% Secure Payment</p>
                        </div>
                        <div class="text-center">
                          <i class="fas fa-undo fa-2x text-warning mb-2"></i>
                          <p>Easy Returns & Instant Refunds</p>
                        </div>
                        <div class="text-center">
                          <i class="fas fa-check-circle fa-2x text-warning mb-2"></i>
                          <p>100% Genuine Product</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
              </>
            )}
          </div>
        </div>
      </section>
      <section>
        <div className="my-5">
          <h4 className="ms-1">Similar Items</h4>
          <div className="row mt-5 mx-0">
            {similarProduct?.map((product) => (
              <div
                className="col-6 col-md-4 col-lg-2-4 mb-4 col-custom"
                key={product._id}
                style={{ paddingLeft: "0", paddingRight: "0" }}
              >
                <Link
                  to={`/product/${product._id}`}
                  className="no-underline"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  <div className="product">
                    <div className="product-card">
                      <img
                        src={product.primaryImageUrl}
                        className="card-img-top"
                        alt={product.productName}
                        style={{ border: "0" }}
                      />
                    </div>
                    <div className="mt-3 prod-desc">
                      <p className="mb-2">{product.productName}</p>
                      <span>
                        <span style={{ fontSize: "16px" }}>
                          {`₹${product.sellingPrice}`}
                        </span>
                        &nbsp;&nbsp;
                        <span
                          style={{
                            textDecoration: "line-through",
                            marginRight: 5,
                            fontSize: "14px",
                            color: "grey",
                          }}
                        >
                          {`₹${product.price}`}
                        </span>
                      </span>
                      <span style={{ color: "green", marginLeft: 10 }}>
                        {`${Math.round(
                          ((product.price - product.sellingPrice) /
                            product.price) *
                            100
                        )}% off`}
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>

      <Footer />
      {/* Snackbar for login prompt */}
      <Snackbar
        open={!!error}
        autoHideDuration={4000}
        onClose={() => setError("")}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={() => setError("")} severity="error">
          {error}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!success}
        autoHideDuration={4000}
        onClose={() => setSuccess("")}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={() => setSuccess("")} severity="success">
          {success}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ProductOverview;
